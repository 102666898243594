import { render, staticRenderFns } from "./frequent_items_list.vue?vue&type=template&id=234c3bbc&"
import script from "./frequent_items_list.vue?vue&type=script&lang=js&"
export * from "./frequent_items_list.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../software/f43f6759c9c0a9539de9d0b8075c216b/parts/gitlab/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports